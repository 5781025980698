<script setup lang="ts">
import { onClickOutside, useElementBounding } from '@vueuse/core';
import { useConstants } from '@/composables/useConstants';
import * as yup from 'yup';
import { useField, useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import type { InsertContract } from '~/types/contract';

// Composables
const { t } = useI18n();
const { hoursAsOptions, minutesAsOptions, daysAsOptions } = useConstants();
const { periodToText } = useConfigurationSchedule();

// Props & Emits
const props = defineProps<{
  name: string;
  schedule?: InsertContract['tariffs'][0]['tariff_schedules'];
  placeholder?: string;
  type?: 'icon';
  icon?: string;
  menuItemsClass?: string;
  disabled?: boolean;
  fitToSelect?: boolean;
}>();

// Data
const schedules = useField<InsertContract['tariffs'][0]['tariff_schedules']>(props.name, {
  initialValue: props.schedule,
});
const open = ref<boolean>(false);
const container = ref(null);
const button = ref<HTMLElement | null>(null);
const { left, width, height, top, y, update } = useElementBounding(button);
const elements = ref<HTMLElement | null>(null);
const { width: elementsWidth, height: elementsHeight, update: reload } = useElementBounding(elements);

// Form
const { handleSubmit, errors, resetForm } = useForm({
  validationSchema: yup.object({
    days_of_week: yup.array().of(yup.string()).min(1, t('contract.days_of_week_required')),
    period_start_hour: yup.number().required(t('global.contract.period_start_hour_required')),
    period_start_minute: yup.number().required(),
    period_end_hour: yup.number().required(),
    period_end_minute: yup.number().required(),
  }),
  initialValues: {
    period_start_hour: 0,
    period_start_minute: 0,
    period_end_hour: 0,
    period_end_minute: 0,
    days_of_week: [],
  },
});

// Methods
onClickOutside(container, () => (open.value = false));

const submit = handleSubmit((values) => {
  schedules.value.value.push({
    days_of_week: values.days_of_week.map((day) => parseInt(day)),
    time_range: [
      `${values.period_start_hour < 10 ? '0' : ''}${values.period_start_hour}:${values.period_start_minute < 10 ? '0' : ''}${values.period_start_minute}`,
      `${values.period_end_hour < 10 ? '0' : ''}${values.period_end_hour}:${values.period_end_minute < 10 ? '0' : ''}${values.period_end_minute}`,
    ],
  });
  resetForm();
});

const openMenu = async () => {
  if (props.disabled) return;
  await update();
  await reload();
  open.value = !open.value;
};

const deletePeriod = (index: number) => {
  schedules.value.value.splice(index, 1);
};

// Computed
const getWindowHeight = computed(() => {
  return window.innerHeight;
});

const getYPosition = computed(() => {
  if (getWindowHeight.value - y.value > elementsHeight.value + 32) {
    return `top: ${top.value + height.value}px;`;
  } else {
    return `top: ${y.value - elementsHeight.value - 16}px;`;
  }
});

watch(open, (value) => {
  if (value) {
    schedules.value.value = [...(props.schedule || [])];
    resetForm();
  }
});
</script>

<template>
  <div ref="container" class="relative inline-block text-left">
    <div
      v-if="!$slots.button"
      ref="button"
      :disabled="disabled"
      :class="[
        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        open
          ? 'border-primary-500 ring-green-100 ring-[2px]'
          : 'border-gray-200 hover:border-gray-300 hover:ring-[1px] hover:ring-gray-100',
        schedules.value.value.length === 0 ? 'justify-between' : 'justify-end',
      ]"
      class="flex relative z-0 h-[32px] items-center w-full border border-gray-200 px-3 rounded-md bg-white text-sm font-medium group-hover:text-gray-700 text-gray-600"
      @click="openMenu()"
    >
      <p v-if="schedules.value.value.length === 0" class="text-sm text-gray-500 font-[400]">
        {{ $t('global.periode.define_periodes') }}
      </p>
      <div v-else class="absolute right-0 top-0 left-0 bottom-0 px-3 flex items-center gap-x-2">
        <ui-badge color="gray">{{ periodToText(schedules.value.value[0]) }}</ui-badge>
        <ui-badge v-if="schedules.value.value.length > 1" color="gray">+ {{ schedules.value.value.length - 1 }}</ui-badge>
      </div>

      <ui-icon
        :class="props.disabled ? 'stroke-gray-400' : 'stroke-gray-600'"
        class="ml-2 h-4 w-4 text-gray-600 group-hover:text-gray-700"
        name="ChevronDown"
      />
    </div>
    <div v-else>
      <slot name="button" />
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-y-95"
      enter-to-class="transform opacity-100 scale-y-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-y-100"
      leave-to-class="transform opacity-0 scale-y-95"
    >
      <div
        v-if="open"
        ref="elements"
        :style="`left: ${left - elementsWidth + width}px; width: ${width}px;};` + getYPosition"
        class="fixed bg-white origin-top-left z-[9999] mt-2 p-4 divide-gray-100 rounded-[8px] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <!-- Periodes -->
        <label class="text-sm text-gray-900">
          {{ $t('global.periodes') }}
        </label>
        <div class="py-4 mb-4 border-b border-gray-200 flex flex-wrap gap-2">
          <div
            v-for="(period, index) in schedules.value.value"
            :key="index"
            class="bg-gray-100 h-6 pl-1.5 text-xs rounded-md flex items-center justify-between"
          >
            <p class="mb-0.5">{{ periodToText(period) }}</p>
            <ui-icon class="h-4 cursor-pointer" name="X" @click="deletePeriod(index)" />
          </div>
          <div v-if="schedules.value.value.length === 0" class="w-full">
            <p class="text-xs text-center text-gray-500">{{ $t('global.periode.all_days') }}</p>
          </div>
        </div>

        <!-- Add periode -->
        <label class="text-sm text-gray-900 mb-4 block">
          {{ $t('global.add_periode') }}
        </label>
        <div class="mb-4">
          <ui-form-input-checkbox :items="daysAsOptions" :label="$t('global.day-s')" name="days_of_week" />
        </div>

        <div class="grid grid-cols-4 gap-x-4 mb-4">
          <!-- Input checkbox -->
          <label class="font-medium text-xs mb-[6px] block text-left text-gray-600 col-span-2">
            {{ $t('global.start_hour') }}
            <span class="text-red-500">*</span>
          </label>
          <label class="font-medium text-xs mb-[6px] block text-left text-gray-600 col-span-2">
            {{ $t('global.end_hour') }}
            <span class="text-red-500">*</span>
          </label>
          <div class="flex gap-x-2 col-span-2 items-center">
            <ui-form-input-select :items="hoursAsOptions" placeholder="12" name="period_start_hour" hide-details />
            <span class="text-gray-500 text-md">h</span>
            <ui-form-input-select :items="minutesAsOptions" placeholder="30" name="period_start_minute" hide-details />
          </div>
          <div class="flex gap-x-2 col-span-2 items-center">
            <ui-form-input-select :items="hoursAsOptions" placeholder="18" name="period_end_hour" hide-details />
            <span class="text-gray-500 text-md">h</span>
            <ui-form-input-select :items="minutesAsOptions" placeholder="30" name="period_end_minute" hide-details />
          </div>
          <p class="text-red-500 text-xs col-span-4 text-end mt-1">
            {{
              errors.period_start_hour ||
              errors.period_start_minute ||
              errors.period_end_hour ||
              errors.period_end_minute ||
              errors.days_of_week
            }}
          </p>
        </div>
        <div class="flex justify-center">
          <ui-button left-icon="Plus" color="secondary" @click="submit">
            {{ $t('global.add_the_periode') }}
          </ui-button>
        </div>
      </div>
    </transition>
  </div>
</template>
