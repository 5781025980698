<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import type { InsertContract } from '~/types/contract';

// Composables
const { monthsAsOptions } = useConstants();
const { periodToText } = useConfigurationSchedule();
const { headers } = useTariffTable();
const { t } = useI18n();

// Props & emits
const emit = defineEmits<{
  (e: 'addTariff'): void;
  (e: 'deleteTariff', index: number): void;
}>();
defineProps<{
  tariffs: InsertContract['tariffs'];
}>();

// Data
const editTariff = ref<number | null>(null);

// Methods
const deleteTariff = (index: number) => {
  appAlertStore().showAlert({
    type: 'error',
    title: t('contract.delete_tariff'),
    message: t('contract.are_you_realy_sure_to_delete_this_tariff'),
    successText: t('contract.the_tariff_is_sucessfully_deleted'),
    callback: () => {
      emit('deleteTariff', index);
    },
  });
};
</script>
<template>
  <ui-card :title="$t('contract.tarrifs')">
    <template #header>
      <ui-button size="sm" left-icon="Plus" @click="$emit('addTariff')">{{ $t('contract.add_tarrif') }}</ui-button>
    </template>
    <ui-data-table :headers="headers" :items="tariffs">
      <template #item-name="{ item, index }">
        <ui-form-input-text
          v-show="editTariff === index"
          :name="`tariffs[${index}].name`"
          hide-details
          :placeholder="$t('contract.off-peack_hour_high_season')"
          class="!w-[220px]"
        />
        <div v-if="editTariff !== index">{{ item.name }}</div>
      </template>
      <template #item-price="{ item, index }">
        <ui-form-input-text
          v-show="editTariff === index"
          :name="`tariffs[${index}].value`"
          type="number"
          hide-details
          placeholder="0.25"
          right-text="€ / kWh"
          class="!w-[120px]"
        />
        <div v-if="editTariff !== index">{{ item.value }} € / kWh</div>
      </template>
      <template #item-month="{ item, index: rowIndex }">
        <ui-form-input-multi-select
          v-show="editTariff === rowIndex"
          :name="`tariffs[${rowIndex}].months`"
          class="!min-w-[350px]"
          hide-details
          :placeholder="$t('global.select_one_or_more_months')"
          :list-title="$t('global.month_plural')"
          :list-selected-title="$t('global.selected_months')"
          :no-items-selected-title="$t('global.all_months')"
          :custom-all-selected-items-text="$t('global.all_months')"
          :items="monthsAsOptions"
        />
        <div v-if="editTariff !== rowIndex" class="flex flex-wrap gap-2">
          <ui-badge v-for="(month, index) in item.months" :key="`schedule-${index}`" color="gray">
            {{ $t(`global.months.${month}`) }}
          </ui-badge>
          <ui-badge v-if="!item.months || item.months?.length === 0" color="gray">{{ $t('global.all_months') }}</ui-badge>
        </div>
      </template>
      <template #item-days-time-slots="{ item, index: rowIndex }">
        <ui-form-input-schedule
          v-show="editTariff === rowIndex"
          :name="`tariffs[${rowIndex}].tariff_schedules`"
          class="w-[386px]"
          :schedule="item.tariff_schedules"
        />
        <div v-if="editTariff !== rowIndex" class="flex flex-wrap gap-2">
          <ui-badge v-for="(tariff_schedule, index) in item.tariff_schedules" :key="`schedule-${index}`" color="gray">
            {{ periodToText(tariff_schedule) }}
          </ui-badge>
          <ui-badge v-if="item.tariff_schedules.length === 0" color="gray">{{ $t('global.all_days') }}</ui-badge>
        </div>
      </template>
      <template #item-action="{ index }">
        <div v-if="editTariff === index" class="flex items-center gap-x-2">
          <ui-button color="primary" left-icon="Check" @click="editTariff = null" />
          <ui-button color="secondary" left-icon="X" @click="editTariff = null" />
        </div>

        <div v-else class="flex items-center gap-x-2 text-gray-500">
          <div class="p-2 rounded hover:bg-gray-200 cursor-pointer" @click="editTariff = index">
            <ui-icon name="Pen" class="w-4 h-4" />
          </div>
          <div class="p-2 rounded hover:bg-gray-200 cursor-pointer" @click="deleteTariff(index)">
            <ui-icon name="Trash2" class="w-4 h-4" />
          </div>
        </div>
      </template>
    </ui-data-table>
  </ui-card>
</template>
